import React from 'react';
import { useSpring, animated } from 'react-spring';

import './index.scss';
import logoAviator from '../../../statics/assets/images/aviator.svg';

function Loading() {
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const duration = getRandomInt(4000, 6000);
  const progressProps = useSpring({
    width: '100%',
    from: { width: '0%' },
    config: { duration, delay: 500, tension: 100, friction: 500 },
  });

  return (
    <div className='loading'>
      <div className='loading__content'>
        <img
          className='loading__content_logo'
          src={logoAviator}
          alt='Aviator'
        />
        <div
          className='loading__content_progress'
          role='progressbar'
          aria-label='Basic example'
          aria-valuenow='75'
          aria-valuemin='0'
          aria-valuemax='100'
        >
          <animated.div
            className='loading__content_progress-bar'
            style={progressProps}
          />
        </div>
      </div>
    </div>
  );
}
export default Loading;
