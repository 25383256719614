import React, { useState, useEffect } from 'react';

function Animation() {
  const [currentImage, setCurrentImage] = useState(1);

  useEffect(() => {
    // Здесь вы можете установить интервал или таймер, чтобы менять изображения с определенной задержкой
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        // В этом примере предполагается, что у вас есть три изображения с именами "image1.svg", "image2.svg" и "image3.svg"
        // Здесь мы меняем текущее изображение на следующее в цикле
        if (prevImage === 4) {
          return 1;
        }
        return prevImage + 1;
      });
    }, 300); // Здесь можно настроить задержку между сменой изображений

    return () => {
      clearInterval(interval); // Очистка интервала при размонтировании компонента
    };
  }, []);

  return (
    <div>
      {/* В зависимости от текущего изображения выводим соответствующий SVG-код */}
      {currentImage === 1 && (
        <svg
          className='logo-plane'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          preserveAspectRatio='none'
          x='0px'
          y='0px'
          width='160'
          height='80'
          viewBox='0 0 150 74'
        >
          <defs>
            <g id='Symbol_5_0_Layer0_0_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 133.55 31.85
L 133.05 31.75 132.85 31.9
Q 132.25 32.15 132.35 32.65 133.2 37.4 134.35 41.9 134.5 42.55 135.4 42.45
L 135.65 42.35
Q 139.7 40.05 142.6 35.95 143.55 34.7 142.2 34.05 139.2 32.65 133.55 31.85
M 133.9 33.95
Q 133.85 33.8 133.85 33.65 133.95 33.4 134.25 33.3
L 134.4 33.25 136.95 33.7
Q 139.6 34.3 140.45 34.7 141.2 35.05 141.1 35.35
L 134.6 37 133.9 33.95
M 135.65 44.8
L 134.85 44.8 135.45 56.05
Q 135.55 57.45 136.3 59.3 136.6 60.35 137.5 62.45
L 141.95 73.4
Q 142.1 73.9 142.5 73.95 143 74.05 143.35 73.7 144.8 72.4 144.7 70.95 144.1 59.4 143.05 54.45
L 139.1 42.3
Q 137.6 43.75 136.15 44.6
L 135.65 44.8
M 142.45 71.3
L 142.4 71
Q 138.55 62.25 137.05 57.55 136.75 56.65 137.15 56.15
L 137.85 55.75 143.3 68
Q 144.1 70.3 142.6 71.05
L 142.45 71.3
M 129.35 0.45
Q 129.2 0.1 128.8 0.05 128.4 -0.1 128.1 0.2 126.55 1.85 126.65 3.65 127.1 14.2 128.25 19.65
L 132 30.05 132.7 29.75 133.2 29.7
Q 134.7 29.85 136.75 30.45
L 135.9 18.85
Q 135.7 16.1 133.8 11.7
L 129.35 0.45
M 128.95 2.05
L 129.05 2.2
Q 132.2 10.1 133.85 15.2 134.5 16.75 133.3 17.15
L 133.15 17.25 133.1 17
Q 128.5 7.95 127.8 5.45 127.25 3.55 128.8 2.15
L 128.95 2.05 Z'
              />
            </g>

            <g id='Symbol_5_0_Layer0_1_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 90.75 -2.25
Q 88.5 -2.05 86.45 -1.5
L 85.15 2.9
Q 83.1 1.2 80.95 -0.25
L 76.8 0.6 77.2 1.85
Q 79.25 3.1 83 5.75 82.35 7.7 81.1 10.5
L 83.5 10.1
Q 83.4 11.15 82.85 11.6 82.45 11.85 81.35 12.15
L 79.15 12.6
Q 78.45 12.75 78.2 13.8 78.1 14.45 78.15 14.95
L 86.15 12.9 86.5 7.45
Q 87.75 8.6 90.05 11.65
L 93.65 10.5
Q 93.2 10.1 88.3 5 90.4 -1.3 90.75 -2.25
M 58.6 -10.05
Q 53.4 -7.25 49.5 -5.45 47.9 -4.8 47.1 -3.75 46.75 -3.4 46.05 -2.15 45.8 -1.65 46.25 -1.55
L 60.5 3.55
Q 60.3 3.25 60.35 3.05 60.35 2.9 60.65 2.8 73.15 -1.2 81.45 -2.8 88.45 -4.05 91.85 -4.4 94.25 -4.55 96.75 -4.3
L 99 -3.9
Q 99.9 -3.55 99.95 -2.7 100.45 0.5 101.65 5.15 102.9 10.05 103.9 12.3 103.45 12.95 102.75 12.85 102.3 12.85 100.8 10.85 100.55 10.75 100.25 10.75 76.85 18.7 42.15 24.8 3.2 31.6 -11.55 28.2
L -11.55 28.3 -17.35 30.95
Q -16.5 31.85 -14.25 34.1 -11.2 35.6 -5.3 33.55
L -4.45 35.55
Q -4.15 36.65 -3.35 36.8 -2.65 36.95 0.4 36.5 11.55 35 38.6 30.75 68.05 26.15 76.9 24.35 94.15 20.95 100.95 18.35 106 16.4 107.1 14.5 108.4 12.25 107.15 6.9 105 -2.9 104.7 -4.05 103.85 -6.2 102.1 -7 100.85 -7.6 98.7 -7.65 95 -7.65 91.4 -7.35 85.9 -6.75 80.5 -6.25 75.8 -5.75 71.9 -8 68 -10.3 67.35 -10.65 65.4 -11.6 64.1 -11.7 62.2 -11.9 58.6 -10.05
M 61.1 -2.05
Q 60.85 -3.15 62.5 -3.55 62.8 -3.6 66.1 -4.65 66.7 -4.85 66.7 -5.4 66.8 -5.95 66.35 -6.2
L 61.9 -9.1
Q 64.1 -10.3 66.5 -9.05 69.7 -7.4 72.3 -5.6 72.85 -5.2 72.75 -4.75 72.65 -4.25 72.2 -4.15
L 61.75 -1.15
Q 61.25 -1.6 61.1 -2.05
M 21.3 -6.85
Q 14.9 -8.85 8.95 -4.6
L 8.95 -4.55 13.45 1.95
Q 13.65 2.25 13.9 2.15
L 20.85 0 21.05 0.9
Q 21.1 1.8 20.75 2.5 20.45 3.1 19.75 3.45
L 15.2 4.95 16.65 7.35 21.9 5.6
Q 22.35 7.85 21.4 8.3
L 18.1 9.7 21.45 15.2 19.4 15.8
Q 10 18.7 8.55 18.45 5.7 17.95 -0.05 12.05 -5.8 5.6 -7.3 4.5 -9.65 2.7 -13.45 3.25 -16.5 3.7 -21.9 6 -23.5 6.65 -23.65 9.5
L -17.35 7.7
Q -17.1 9.6 -18.05 9.9 -21.25 11.05 -23.7 11.75 -24.6 12.25 -24.5 14.8
L -21.6 14.15
Q -21.35 14.05 -21.2 14.25
L -11.25 26.7
Q -10.5 26.65 -7.95 26.1
L -15.05 15.35
Q -15.15 15.15 -15.1 14.95 -14.95 14.75 -14.8 14.65 -12.85 14.1 -11.95 14.05 -9.65 13.9 -8 15.5 -6.3 17.15 -3.9 18.6 -0.25 20.7 2.95 21 4.55 21.2 4.45 21.75
L 2.25 22.45
Q 1.45 22.65 1.4 23.8 1.45 24.35 1.6 24.85
L 25.6 19.9
Q 25.8 19.75 26 19.95
L 28.35 22.75
Q 28.75 23.4 29.5 23.3 33.85 22.95 51.95 19.1 70 15.1 71.35 14.3 72 13.85 72.3 12.3 72.4 11.35 71.15 10.9 31.45 -3.85 21.3 -6.85 Z'
              />
            </g>
          </defs>

          <g transform='matrix( 1, 0, 0, 1, 0,0) '>
            <g transform='matrix( 1, 0, 0, 1, 0,0) '>
              <use xlinkHref='#Symbol_5_0_Layer0_0_FILL' />
            </g>

            <g transform='matrix( 1, 0, 0, 1, 24.5,34.75) '>
              <use xlinkHref='#Symbol_5_0_Layer0_1_FILL' />
            </g>
          </g>
        </svg>
      )}
      {currentImage === 2 && (
        <svg
          className='logo-plane'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          preserveAspectRatio='none'
          x='0px'
          y='0px'
          width='160'
          height='80'
          viewBox='0 0 150 74'
        >
          <defs>
            <g id='Symbol_6_0_Layer0_0_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 133.05 28.5
L 132.85 28.65
Q 132.25 28.9 132.35 29.4 133.2 34.15 134.35 38.65 134.5 39.3 135.4 39.2
L 135.65 39.1
Q 139.7 36.8 142.6 32.7 143.55 31.45 142.2 30.8 139.2 29.4 133.55 28.6
L 133.05 28.5
M 133.9 30.7
Q 133.85 30.55 133.85 30.4 133.95 30.15 134.25 30.05
L 134.4 30 136.95 30.45
Q 139.6 31.05 140.45 31.45 141.2 31.8 141.1 32.1
L 134.6 33.75 133.9 30.7
M 139.15 39.05
Q 137.6 40.4 136.15 41.35
L 135.65 41.6 134.9 41.55 135.55 52.8
Q 135.6 54.2 136.25 56.1 136.65 57.1 137.55 59.1 137.95 60.15 141.05 66.95 141.25 67.35 141.7 67.5 142.2 67.6 142.5 67.2 143.75 65.75 143.75 64.4 143.75 61.3 143.65 58.75 143.6 53.75 143.15 51.25
L 139.15 39.05
M 142.45 61.5
Q 142.9 63 142.4 63.9 142.15 64.35 141.7 64.6
L 141.5 64.7 141.45 64.5
Q 138.6 59.05 137.15 54.3 136.85 53.5 137.25 52.95
L 137.8 52.6 137.85 52.5 142.45 61.5
M 135.15 11.7
Q 135.1 11.5 133.9 8.45 131.15 3.15 130.15 0.45 129.95 0.1 129.55 0.05 129.15 -0.1 128.9 0.2 128.3 0.8 127.8 1.8 127.35 2.9 127.35 3.65 127.35 4.5 127.55 9.05 127.8 13.85 128.25 16.4
L 131.95 26.8 132.75 26.5 133.2 26.5
Q 134.8 26.65 136.85 27.25
L 135.9 15.6
Q 135.85 13.75 135.15 11.7
M 129.75 2.05
L 129.8 2.2
Q 132.05 7.05 134 11.95 134.55 13.5 133.35 13.95
L 133.25 14 133.2 13.8
Q 129.7 7.85 128.65 5.4 128.1 4.35 128.8 3.1 129.15 2.55 129.55 2.15
L 129.75 2.05 Z'
              />
            </g>

            <g id='Symbol_6_0_Layer0_1_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 90.75 -2.25
Q 88.5 -2.05 86.45 -1.5
L 85.15 2.9
Q 83.1 1.2 80.95 -0.25
L 76.8 0.6 77.2 1.85
Q 79.25 3.1 83 5.75 82.35 7.7 81.1 10.5
L 83.5 10.1
Q 83.4 11.15 82.85 11.6 82.45 11.85 81.35 12.15
L 79.15 12.6
Q 78.45 12.75 78.2 13.8 78.1 14.45 78.15 14.95
L 86.15 12.9 86.5 7.45
Q 87.75 8.6 90.05 11.65
L 93.65 10.5
Q 93.2 10.1 88.3 5 90.4 -1.3 90.75 -2.25
M 58.6 -10.05
Q 53.4 -7.25 49.5 -5.45 47.9 -4.8 47.1 -3.75 46.75 -3.4 46.05 -2.15 45.8 -1.65 46.25 -1.55
L 60.5 3.55
Q 60.3 3.25 60.35 3.05 60.35 2.9 60.65 2.8 73.15 -1.2 81.45 -2.8 88.45 -4.05 91.85 -4.4 94.25 -4.55 96.75 -4.3
L 99 -3.9
Q 99.9 -3.55 99.95 -2.7 100.45 0.5 101.65 5.15 102.9 10.05 103.9 12.3 103.45 12.95 102.75 12.85 102.3 12.85 100.8 10.85 100.55 10.75 100.25 10.75 76.85 18.7 42.15 24.8 3.2 31.6 -11.55 28.2
L -11.55 28.3 -17.35 30.95
Q -16.5 31.85 -14.25 34.1 -11.2 35.6 -5.3 33.55
L -4.45 35.55
Q -4.15 36.65 -3.35 36.8 -2.65 36.95 0.4 36.5 11.55 35 38.6 30.75 68.05 26.15 76.9 24.35 94.15 20.95 100.95 18.35 106 16.4 107.1 14.5 108.4 12.25 107.15 6.9 105 -2.9 104.7 -4.05 103.85 -6.2 102.1 -7 100.85 -7.6 98.7 -7.65 95 -7.65 91.4 -7.35 85.9 -6.75 80.5 -6.25 75.8 -5.75 71.9 -8 68 -10.3 67.35 -10.65 65.4 -11.6 64.1 -11.7 62.2 -11.9 58.6 -10.05
M 61.1 -2.05
Q 60.85 -3.15 62.5 -3.55 62.8 -3.6 66.1 -4.65 66.7 -4.85 66.7 -5.4 66.8 -5.95 66.35 -6.2
L 61.9 -9.1
Q 64.1 -10.3 66.5 -9.05 69.7 -7.4 72.3 -5.6 72.85 -5.2 72.75 -4.75 72.65 -4.25 72.2 -4.15
L 61.75 -1.15
Q 61.25 -1.6 61.1 -2.05
M 21.3 -6.85
Q 14.9 -8.85 8.95 -4.6
L 8.95 -4.55 13.45 1.95
Q 13.65 2.25 13.9 2.15
L 20.85 0 21.05 0.9
Q 21.1 1.8 20.75 2.5 20.45 3.1 19.75 3.45
L 15.2 4.95 16.65 7.35 21.9 5.6
Q 22.35 7.85 21.4 8.3
L 18.1 9.7 21.45 15.2 19.4 15.8
Q 10 18.7 8.55 18.45 5.7 17.95 -0.05 12.05 -5.8 5.6 -7.3 4.5 -9.65 2.7 -13.45 3.25 -16.5 3.7 -21.9 6 -23.5 6.65 -23.65 9.5
L -17.35 7.7
Q -17.1 9.6 -18.05 9.9 -21.25 11.05 -23.7 11.75 -24.6 12.25 -24.5 14.8
L -21.6 14.15
Q -21.35 14.05 -21.2 14.25
L -11.25 26.7
Q -10.5 26.65 -7.95 26.1
L -15.05 15.35
Q -15.15 15.15 -15.1 14.95 -14.95 14.75 -14.8 14.65 -12.85 14.1 -11.95 14.05 -9.65 13.9 -8 15.5 -6.3 17.15 -3.9 18.6 -0.25 20.7 2.95 21 4.55 21.2 4.45 21.75
L 2.25 22.45
Q 1.45 22.65 1.4 23.8 1.45 24.35 1.6 24.85
L 25.6 19.9
Q 25.8 19.75 26 19.95
L 28.35 22.75
Q 28.75 23.4 29.5 23.3 33.85 22.95 51.95 19.1 70 15.1 71.35 14.3 72 13.85 72.3 12.3 72.4 11.35 71.15 10.9 31.45 -3.85 21.3 -6.85 Z'
              />
            </g>
          </defs>

          <g transform='matrix( 1, 0, 0, 1, 0,3.2) '>
            <g transform='matrix( 1, 0, 0, 1, 0,0) '>
              <use xlinkHref='#Symbol_6_0_Layer0_0_FILL' />
            </g>

            <g transform='matrix( 1, 0, 0, 1, 24.5,31.5) '>
              <use xlinkHref='#Symbol_6_0_Layer0_1_FILL' />
            </g>
          </g>
        </svg>
      )}
      {currentImage === 3 && (
        <svg
          className='logo-plane'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          preserveAspectRatio='none'
          x='0px'
          y='0px'
          width='160'
          height='80'
          viewBox='0 0 150 74'
        >
          <defs>
            <g id='Symbol_7_0_Layer0_0_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 142.6 24.6
Q 143.55 23.35 142.2 22.7 139.2 21.3 133.55 20.5
L 133.05 20.4 132.85 20.55
Q 132.25 20.8 132.35 21.3 133.2 26.05 134.35 30.55 134.5 31.2 135.4 31.1
L 135.65 31
Q 139.7 28.7 142.6 24.6
M 134.25 21.95
L 134.4 21.9 136.95 22.35
Q 139.6 22.95 140.45 23.35 141.2 23.7 141.1 24
L 134.6 25.65 133.9 22.6
Q 133.85 22.45 133.85 22.3 133.95 22.05 134.25 21.95
M 139.1 31
Q 137.55 32.35 136.1 33.3
L 135.6 33.45 134.9 33.55 135.1 41.3
Q 135.2 42.8 138.8 51.35 139 51.8 139.35 51.9 139.8 51.95 140.2 51.65 140.85 51 141.1 50.6 141.3 50.15 141.45 48.95 142.1 44.4 142.1 40.55 142.2 40 141.85 39.1
L 139.1 31
M 136.45 42.8
Q 136.05 42 136.45 41.4
L 137.15 41 140.05 46.1
Q 141.05 48.05 139.45 49.05
L 139.3 49.15 139.2 49 136.45 42.8
M 132.85 0.6
Q 132.65 0.15 132.4 0.15 131.75 -0.15 131.5 0.25 131.2 0.5 130.55 1.5 130.1 2.45 129.95 2.85 129.5 10.2 129.5 11.4
L 129.55 11.9 132.05 18.7 132.7 18.3 135.4 18.7
Q 136.35 18.9 136.75 19.15
L 136.55 10.65
Q 136.45 9.8 136 8.5 135.55 7 134.4 4.35 133.25 1.65 132.85 0.6
M 131.8 3.3
L 132.3 2.8 132.45 2.95 135.2 9.15
Q 135.8 10.5 134.55 10.85
L 134.4 10.95 131.7 5.85
Q 130.9 4.25 131.8 3.3 Z'
              />
            </g>

            <g id='Symbol_7_0_Layer0_1_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 90.75 -2.25
Q 88.5 -2.05 86.45 -1.5
L 85.15 2.9
Q 83.1 1.2 80.95 -0.25
L 76.8 0.6 77.2 1.85
Q 79.25 3.1 83 5.75 82.35 7.7 81.1 10.5
L 83.5 10.1
Q 83.4 11.15 82.85 11.6 82.45 11.85 81.35 12.15
L 79.15 12.6
Q 78.45 12.75 78.2 13.8 78.1 14.45 78.15 14.95
L 86.15 12.9 86.5 7.45
Q 87.75 8.6 90.05 11.65
L 93.65 10.5
Q 93.2 10.1 88.3 5 90.4 -1.3 90.75 -2.25
M 58.6 -10.05
Q 53.4 -7.25 49.5 -5.45 47.9 -4.8 47.1 -3.75 46.75 -3.4 46.05 -2.15 45.8 -1.65 46.25 -1.55
L 60.5 3.55
Q 60.3 3.25 60.35 3.05 60.35 2.9 60.65 2.8 73.15 -1.2 81.45 -2.8 88.45 -4.05 91.85 -4.4 94.25 -4.55 96.75 -4.3
L 99 -3.9
Q 99.9 -3.55 99.95 -2.7 100.45 0.5 101.65 5.15 102.9 10.05 103.9 12.3 103.45 12.95 102.75 12.85 102.3 12.85 100.8 10.85 100.55 10.75 100.25 10.75 76.85 18.7 42.15 24.8 3.2 31.6 -11.55 28.2
L -11.55 28.3 -17.35 30.95
Q -16.5 31.85 -14.25 34.1 -11.2 35.6 -5.3 33.55
L -4.45 35.55
Q -4.15 36.65 -3.35 36.8 -2.65 36.95 0.4 36.5 11.55 35 38.6 30.75 68.05 26.15 76.9 24.35 94.15 20.95 100.95 18.35 106 16.4 107.1 14.5 108.4 12.25 107.15 6.9 105 -2.9 104.7 -4.05 103.85 -6.2 102.1 -7 100.85 -7.6 98.7 -7.65 95 -7.65 91.4 -7.35 85.9 -6.75 80.5 -6.25 75.8 -5.75 71.9 -8 68 -10.3 67.35 -10.65 65.4 -11.6 64.1 -11.7 62.2 -11.9 58.6 -10.05
M 61.1 -2.05
Q 60.85 -3.15 62.5 -3.55 62.8 -3.6 66.1 -4.65 66.7 -4.85 66.7 -5.4 66.8 -5.95 66.35 -6.2
L 61.9 -9.1
Q 64.1 -10.3 66.5 -9.05 69.7 -7.4 72.3 -5.6 72.85 -5.2 72.75 -4.75 72.65 -4.25 72.2 -4.15
L 61.75 -1.15
Q 61.25 -1.6 61.1 -2.05
M 21.3 -6.85
Q 14.9 -8.85 8.95 -4.6
L 8.95 -4.55 13.45 1.95
Q 13.65 2.25 13.9 2.15
L 20.85 0 21.05 0.9
Q 21.1 1.8 20.75 2.5 20.45 3.1 19.75 3.45
L 15.2 4.95 16.65 7.35 21.9 5.6
Q 22.35 7.85 21.4 8.3
L 18.1 9.7 21.45 15.2 19.4 15.8
Q 10 18.7 8.55 18.45 5.7 17.95 -0.05 12.05 -5.8 5.6 -7.3 4.5 -9.65 2.7 -13.45 3.25 -16.5 3.7 -21.9 6 -23.5 6.65 -23.65 9.5
L -17.35 7.7
Q -17.1 9.6 -18.05 9.9 -21.25 11.05 -23.7 11.75 -24.6 12.25 -24.5 14.8
L -21.6 14.15
Q -21.35 14.05 -21.2 14.25
L -11.25 26.7
Q -10.5 26.65 -7.95 26.1
L -15.05 15.35
Q -15.15 15.15 -15.1 14.95 -14.95 14.75 -14.8 14.65 -12.85 14.1 -11.95 14.05 -9.65 13.9 -8 15.5 -6.3 17.15 -3.9 18.6 -0.25 20.7 2.95 21 4.55 21.2 4.45 21.75
L 2.25 22.45
Q 1.45 22.65 1.4 23.8 1.45 24.35 1.6 24.85
L 25.6 19.9
Q 25.8 19.75 26 19.95
L 28.35 22.75
Q 28.75 23.4 29.5 23.3 33.85 22.95 51.95 19.1 70 15.1 71.35 14.3 72 13.85 72.3 12.3 72.4 11.35 71.15 10.9 31.45 -3.85 21.3 -6.85 Z'
              />
            </g>
          </defs>

          <g transform='matrix( 1, 0, 0, 1, 0,11.2) '>
            <g transform='matrix( 1, 0, 0, 1, 0,0) '>
              <use xlinkHref='#Symbol_7_0_Layer0_0_FILL' />
            </g>

            <g transform='matrix( 1, 0, 0, 1, 24.5,23.4) '>
              <use xlinkHref='#Symbol_7_0_Layer0_1_FILL' />
            </g>
          </g>
        </svg>
      )}
      {currentImage === 4 && (
        <svg
          className='logo-plane'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          preserveAspectRatio='none'
          x='0px'
          y='0px'
          width='160'
          height='80'
          viewBox='0 0 150 74'
        >
          <defs>
            <g id='Symbol_8_0_Layer0_0_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 133.05 28.5
L 132.85 28.65
Q 132.25 28.9 132.35 29.4 133.2 34.15 134.35 38.65 134.5 39.3 135.4 39.2
L 135.65 39.1
Q 139.7 36.8 142.6 32.7 143.55 31.45 142.2 30.8 139.2 29.4 133.55 28.6
L 133.05 28.5
M 133.9 30.7
Q 133.85 30.55 133.85 30.4 133.95 30.15 134.25 30.05
L 134.4 30 136.95 30.45
Q 139.6 31.05 140.45 31.45 141.2 31.8 141.1 32.1
L 134.6 33.75 133.9 30.7
M 139.15 39.05
Q 137.6 40.4 136.15 41.35
L 135.65 41.6 134.9 41.55 135.55 52.8
Q 135.6 54.2 136.25 56.1 136.65 57.1 137.55 59.1 137.95 60.15 141.05 66.95 141.25 67.35 141.7 67.5 142.2 67.6 142.5 67.2 143.75 65.75 143.75 64.4 143.75 61.3 143.65 58.75 143.6 53.75 143.15 51.25
L 139.15 39.05
M 142.45 61.5
Q 142.9 63 142.4 63.9 142.15 64.35 141.7 64.6
L 141.5 64.7 141.45 64.5
Q 138.6 59.05 137.15 54.3 136.85 53.5 137.25 52.95
L 137.8 52.6 137.85 52.5 142.45 61.5
M 135.15 11.7
Q 135.1 11.5 133.9 8.45 131.15 3.15 130.15 0.45 129.95 0.1 129.55 0.05 129.15 -0.1 128.9 0.2 128.3 0.8 127.8 1.8 127.35 2.9 127.35 3.65 127.35 4.5 127.55 9.05 127.8 13.85 128.25 16.4
L 131.95 26.8 132.75 26.5 133.2 26.5
Q 134.8 26.65 136.85 27.25
L 135.9 15.6
Q 135.85 13.75 135.15 11.7
M 129.75 2.05
L 129.8 2.2
Q 132.05 7.05 134 11.95 134.55 13.5 133.35 13.95
L 133.25 14 133.2 13.8
Q 129.7 7.85 128.65 5.4 128.1 4.35 128.8 3.1 129.15 2.55 129.55 2.15
L 129.75 2.05 Z'
              />
            </g>

            <g id='Symbol_8_0_Layer0_1_FILL'>
              <path
                fill='#E50539'
                stroke='none'
                d='
M 90.75 -2.25
Q 88.5 -2.05 86.45 -1.5
L 85.15 2.9
Q 83.1 1.2 80.95 -0.25
L 76.8 0.6 77.2 1.85
Q 79.25 3.1 83 5.75 82.35 7.7 81.1 10.5
L 83.5 10.1
Q 83.4 11.15 82.85 11.6 82.45 11.85 81.35 12.15
L 79.15 12.6
Q 78.45 12.75 78.2 13.8 78.1 14.45 78.15 14.95
L 86.15 12.9 86.5 7.45
Q 87.75 8.6 90.05 11.65
L 93.65 10.5
Q 93.2 10.1 88.3 5 90.4 -1.3 90.75 -2.25
M 58.6 -10.05
Q 53.4 -7.25 49.5 -5.45 47.9 -4.8 47.1 -3.75 46.75 -3.4 46.05 -2.15 45.8 -1.65 46.25 -1.55
L 60.5 3.55
Q 60.3 3.25 60.35 3.05 60.35 2.9 60.65 2.8 73.15 -1.2 81.45 -2.8 88.45 -4.05 91.85 -4.4 94.25 -4.55 96.75 -4.3
L 99 -3.9
Q 99.9 -3.55 99.95 -2.7 100.45 0.5 101.65 5.15 102.9 10.05 103.9 12.3 103.45 12.95 102.75 12.85 102.3 12.85 100.8 10.85 100.55 10.75 100.25 10.75 76.85 18.7 42.15 24.8 3.2 31.6 -11.55 28.2
L -11.55 28.3 -17.35 30.95
Q -16.5 31.85 -14.25 34.1 -11.2 35.6 -5.3 33.55
L -4.45 35.55
Q -4.15 36.65 -3.35 36.8 -2.65 36.95 0.4 36.5 11.55 35 38.6 30.75 68.05 26.15 76.9 24.35 94.15 20.95 100.95 18.35 106 16.4 107.1 14.5 108.4 12.25 107.15 6.9 105 -2.9 104.7 -4.05 103.85 -6.2 102.1 -7 100.85 -7.6 98.7 -7.65 95 -7.65 91.4 -7.35 85.9 -6.75 80.5 -6.25 75.8 -5.75 71.9 -8 68 -10.3 67.35 -10.65 65.4 -11.6 64.1 -11.7 62.2 -11.9 58.6 -10.05
M 61.1 -2.05
Q 60.85 -3.15 62.5 -3.55 62.8 -3.6 66.1 -4.65 66.7 -4.85 66.7 -5.4 66.8 -5.95 66.35 -6.2
L 61.9 -9.1
Q 64.1 -10.3 66.5 -9.05 69.7 -7.4 72.3 -5.6 72.85 -5.2 72.75 -4.75 72.65 -4.25 72.2 -4.15
L 61.75 -1.15
Q 61.25 -1.6 61.1 -2.05
M 21.3 -6.85
Q 14.9 -8.85 8.95 -4.6
L 8.95 -4.55 13.45 1.95
Q 13.65 2.25 13.9 2.15
L 20.85 0 21.05 0.9
Q 21.1 1.8 20.75 2.5 20.45 3.1 19.75 3.45
L 15.2 4.95 16.65 7.35 21.9 5.6
Q 22.35 7.85 21.4 8.3
L 18.1 9.7 21.45 15.2 19.4 15.8
Q 10 18.7 8.55 18.45 5.7 17.95 -0.05 12.05 -5.8 5.6 -7.3 4.5 -9.65 2.7 -13.45 3.25 -16.5 3.7 -21.9 6 -23.5 6.65 -23.65 9.5
L -17.35 7.7
Q -17.1 9.6 -18.05 9.9 -21.25 11.05 -23.7 11.75 -24.6 12.25 -24.5 14.8
L -21.6 14.15
Q -21.35 14.05 -21.2 14.25
L -11.25 26.7
Q -10.5 26.65 -7.95 26.1
L -15.05 15.35
Q -15.15 15.15 -15.1 14.95 -14.95 14.75 -14.8 14.65 -12.85 14.1 -11.95 14.05 -9.65 13.9 -8 15.5 -6.3 17.15 -3.9 18.6 -0.25 20.7 2.95 21 4.55 21.2 4.45 21.75
L 2.25 22.45
Q 1.45 22.65 1.4 23.8 1.45 24.35 1.6 24.85
L 25.6 19.9
Q 25.8 19.75 26 19.95
L 28.35 22.75
Q 28.75 23.4 29.5 23.3 33.85 22.95 51.95 19.1 70 15.1 71.35 14.3 72 13.85 72.3 12.3 72.4 11.35 71.15 10.9 31.45 -3.85 21.3 -6.85 Z'
              />
            </g>
          </defs>

          <g transform='matrix( 1, 0, 0, 1, 0,3.2) '>
            <g transform='matrix( 1, 0, 0, 1, 0,0) '>
              <use xlinkHref='#Symbol_8_0_Layer0_0_FILL' />
            </g>

            <g transform='matrix( 1, 0, 0, 1, 24.5,31.5) '>
              <use xlinkHref='#Symbol_8_0_Layer0_1_FILL' />
            </g>
          </g>
        </svg>
      )}
    </div>
  );
}

export default Animation;
