import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import logoAviator from '../../statics/assets/images/aviator.svg';
import Animation from '../Design/Animation';

import './index.scss';

function MainContent() {
  const [showSignalButton, setShowSignalButton] = useState(false);
  const [generatedNumber, setGeneratedNumber] = useState(null);

  const generateNumber = () => {
    setShowSignalButton(true);
    const randomNumber = Math.random();
    let number;
    toast.success(
      'Внимание! Больше точных прогнозов в реальном времени можно получить в нашем Telegram AI-Боте',
      { autoClose: 3000, position: toast.POSITION.BOTTOM_CENTER },
    );
    if (randomNumber < 0.7) {
      number = (Math.random() * (2 - 1.5) + 1.5).toFixed(2);
    } else if (randomNumber < 0.9) {
      number = (Math.random() * (3 - 2) + 2).toFixed(2);
    } else {
      number = (Math.random() * (5 - 3) + 3).toFixed(2);
    }

    setGeneratedNumber(number);
  };

  return (
    <div>
      <div className='main-content'>
        <div className='main-container'>
          {showSignalButton && (
            <Button
              target='_blank'
              href='https://t.me/casinohack_bot'
              className='buttonPlay'
              variant='outline-secondary'
            >
              Telegram Bot
            </Button>
          )}
          <div className='main-container__logo-image'>
            <div className='main-container__logo-image_gray-circle'>
              {generatedNumber ? (
                <a
                  className='result'
                  target='_blank'
                  href='https://t.me/casinohack_bot'
                  rel='noreferrer'
                >
                  👉 Получить Сигнал 👈
                </a>
              ) : (
                <Animation />
              )}
            </div>
          </div>
        </div>
        <div className='bottom-container'>
          <img
            src={logoAviator}
            alt='Aviator'
            className='bottom-container__logo-name'
          />
          <p className='bottom-container__bottom-text'>
            Прогнозы действуют только на официальных
            <br /> сайтах и актуальных зеркалах 1WIN
          </p>
          {!showSignalButton && (
            <Button
              onClick={generateNumber}
              className='button'
              variant='outline-secondary'
            >
              Начать
            </Button>
          )}
          <ToastContainer theme='dark' />
        </div>
      </div>
    </div>
  );
}

export default MainContent;
